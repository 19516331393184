import axios from "axios";

import { MEMBERS_ENDPOINT } from "../constants";

import type { User, UserDTO } from "../types";

const dateToYYYYMMDD = (date: Date) => {
  return (
    date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" : "") + (date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" : "") + date.getDate()
  );
};

export const submitForm = async (user: User): Promise<false | { id: string }> => {
  try {
    user.signature = user.signature.replace('^data:image/.+;base64,', '')
    if (user.parentalSignature) {
      user.parentalSignature = user.parentalSignature.replace('^data:image/.+;base64,', '')
    }
    else{
      // Yes this is janky, but the API expects a string and not null and I don't have time to fix it rn
      user.parentalSignature = ""
    }

    const userDTO = { ...user, birthDate: dateToYYYYMMDD(user.birthDate as Date), } as UserDTO;

    const res = await axios.post(MEMBERS_ENDPOINT, userDTO);

    return res.data;
  } catch (e) {
    console.error(`POST ${MEMBERS_ENDPOINT} failed ${e}`);
    return false;
  }
};
