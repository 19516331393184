import React, { useState } from "react";

export type ErrorType<T> = { [key in keyof T]: string };

export const useForm = <T extends Record<string, unknown>>(initialValue: T, formValidator: (formState: T) => ErrorType<T>) => {
  // Form state for React state control
  const [formState, setFormState] = useState<T>(initialValue);

  // Creates an empty error state from the initial form value
  const getEmptyErrorState = () => {
    return Object.keys(initialValue).reduce((acc, key) => {
      return { ...acc, [key]: "" };
    }, {}) as ErrorType<T>;
  };

  // Error state for React state control
  const [errorState, setErrorState] = useState<ErrorType<T>>(getEmptyErrorState());

  // Handles changes in form by looking at event target name and value
  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Retrieve the value from the event
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    // Retrieve the name from the event
    const name = e.target.name;

    // Update state with new value
    setFormState((oldFormState) => {
      return {
        ...oldFormState,
        [name]: value,
      };
    });

    // Reset error state
    setErrorState((oldErrorState) => {
      return {
        ...oldErrorState,
        [name]: "",
      };
    });
  };

  // Validates the form
  const validateForm = (formState: T) => {
    const errState = formValidator(formState);

    setErrorState(errState);

    return errState;
  };

  // Validates a single field
  const validateSingle: React.FocusEventHandler<HTMLInputElement> = (e) => {
    // Get name
    const name = e.target.name;

    // Get error state
    const errState = formValidator(formState);

    setErrorState((oldErrState) => {
      return {
        ...oldErrState,
        [name]: errState[name],
      };
    });
  };

  // Verifies whether the form is valid
  const formIsValid = (errorState: ErrorType<T>): boolean => {
    return Object.values(errorState).every((val) => !val);
  };

  return {
    formState,
    errorState,
    setFormState,
    handleFormChange,
    validateForm,
    validateSingle,
    formIsValid,
    getEmptyErrorState,
  };
};

export default useForm;
