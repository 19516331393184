import { Box, IconButton, TextField, TextFieldProps } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const DrawInput = ({ rowCount, ...textFieldProps }: { rowCount: number } & TextFieldProps): React.ReactElement => {
  type Point = [number, number];
  type PageLocation = {
    clientX: number;
    clientY: number;
  };

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvasData, setCanvasData] = useState<string>("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const textArea = textAreaRef.current;
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value")?.set;

    if (!canvas || !textArea || !nativeInputValueSetter) {
      return;
    }

    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setCanvasData("");
    nativeInputValueSetter.call(textArea, "");
    textArea.dispatchEvent(new Event("input", { bubbles: true }));
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const textArea = textAreaRef.current;
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value")?.set;

    if (!canvas || !textArea || !nativeInputValueSetter) {
      return;
    }

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return;
    }

    ctx.strokeStyle = "#000000";
    ctx.lineJoin = "round";
    ctx.lineWidth = 5;

    const relPos = (pt: PageLocation): Point => {
      const boundingRect = canvas.getBoundingClientRect();

      return [pt.clientX - boundingRect.left, pt.clientY - boundingRect.top];
    };

    const drawStart = (pt: Point) => {
      ctx.beginPath();
      ctx.moveTo(...pt);
      ctx.stroke();
    };

    const drawMove = (pt: Point) => {
      ctx.lineTo(...pt);
      ctx.stroke();
    };

    const pointerDown = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      drawStart(relPos("touches" in e ? e.touches[0] : e));
    };

    const pointerMove = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      drawMove(relPos("touches" in e ? e.touches[0] : e));
    };

    const addListeners = (move: "mousemove" | "touchmove", stop: "mouseup" | "touchend") => (e: MouseEvent | TouchEvent) => {
      pointerDown(e);
      setCanvasData(canvas.toDataURL("image/png"));
      canvas.addEventListener(move, pointerMove);
      canvas.addEventListener(stop, ctx.closePath);
    };

    const removeListeners = (move: "mousemove" | "touchmove", stop: "mouseup" | "touchend") => (e: MouseEvent | TouchEvent) => {
      nativeInputValueSetter.call(textArea, canvas.toDataURL("image/png"));
      textArea.dispatchEvent(new Event("input", { bubbles: true }));
      canvas.removeEventListener(move, pointerMove);
      canvas.removeEventListener(stop, ctx.closePath);
    };

    canvas.addEventListener("mousedown", addListeners("mousemove", "mouseup"));
    canvas.addEventListener("touchstart", addListeners("touchmove", "touchend"));
    canvas.addEventListener("mouseup", removeListeners("mousemove", "mouseup"));
    canvas.addEventListener("touchend", removeListeners("touchmove", "touchend"));
  }, [canvasRef]);

  return (
    <Box style={{ position: "relative", width: "100%", display: "flex" }}>
      <canvas ref={canvasRef} style={{ position: "absolute", width: "100%", height: "100%", padding: "6px" }} />
      <IconButton
        onClick={clearCanvas}
        style={{
          position: "absolute",
          zIndex: 1,
          top: 0,
          right: 0,
        }}
        size="large"
      >
        <Delete />
      </IconButton>
      <StyledTextField {...textFieldProps} value={canvasData} multiline rows={rowCount} inputRef={textAreaRef} />
    </Box>
  );
};

const StyledTextField = styled(TextField)`
  z-index: -1;
  flex-grow: 1;

  & > * > textarea {
    color: rgba(0, 0, 0, 0);
    overflow-y: hidden;
  }
`;
