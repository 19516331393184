import React from "react";
import { Box, Typography, TypographyProps } from "@mui/material";

export const Header: React.FunctionComponent<TypographyProps> = ({ children, ...typographyProps }) => {
  return (
    <Box my={1}>
      <Typography {...typographyProps} variant="h4" color="primary">
        {children}
      </Typography>
    </Box>
  );
};

export default Header;
