import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import ROUTES from "./routes";

import Home from "../domain/home";
import Confirmation from "../domain/confirmation";
import { Layout } from "../components/layout";
import FromApp from "./fromApp";
import { SVSIM_URL } from "../constants";

export const Router = (): React.ReactElement => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={ROUTES.HOME}>
          <Layout>
            <Home />
          </Layout>
        </Route>
        <Route exact path={ROUTES.CONFIRMATION}>
          <FromApp>
            <Layout>
              <Confirmation />
            </Layout>
          </FromApp>
        </Route>
        {/* Default route */}
        <Route
          component={() => {
            window.location.href = SVSIM_URL;
            return null;
          }}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
