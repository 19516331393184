import React, { useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Router from "./router/main-router";

const theme = createTheme({
  palette: {
    primary: {
      main: "#005380",
    },
  },
});

const App = (): React.ReactElement => {
  const { t, i18n } = useTranslation("form");

  // Update website title on language change
  useEffect(() => {
    document.title = t("text.signUpForSIM");
  }, [t, i18n.language]);

  return (
    <>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
