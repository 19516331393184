import React, { useState } from "react";
import styled from "styled-components";
import { ArrowBackIos } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useHistory } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import type User from "../../types/user";
import useForm, { ErrorType } from "../../utils/useForm";
import { Trans, useTranslation } from "react-i18next";
import { submitForm } from "../../actions/submit-form";
import { ConfirmationModalContent } from "./confirm";
import { DrawInput } from "../../components/signatureInput";
import Header from "../../components/text/header";
import { Dayjs } from "dayjs";
import "dayjs/locale/en-gb";
import "dayjs/locale/nl";

export const Home = (): React.ReactElement => {
  // Initial form state
  const initialFormState: User = {
    nickName: "",
    initials: "",
    lastName: "",
    birthDate: null,
    study: "",
    studyStartYear: 0,
    studentNumber: "",
    studyLocation: "",
    referrer: "",
    street: "",
    houseNumber: "",
    postalCode: "",
    city: "",
    phoneNumber: "",
    email: "",
    statementAgreement: false,
    accountHolder: "",
    bankAccount: "",
    signature: "",
    parentalSignature: null,
  };

  const minSignupAge = 16
  function parseAge(input: Date) {
    return Math.abs(new Date(Date.now() - input.getTime()).getUTCFullYear() - 1970)
  }
  function getAgeText(input: Date | null) {
    if (input) {
      var parsedAge = parseAge(input);
      if (parsedAge > minSignupAge && parsedAge < 18) {
        return t("text.informGuardian")
      }
    }
  }

  const formValidator = (formState: User): ErrorType<User> => {
    let errorState = { ...getEmptyErrorState() };

    // Set default error text for all components since everything is required
    Object.entries(formState).forEach(([key, val]) => {
      let errText = val ? "" : t("input." + key) + " " + t("text.isRequired");

      // StudyOther is not required when study is not other
      if (key === "studyOther") {
        if (formState.study !== "Anders") {
          errText = "";
        }
      }

      // StudyLocation is not required when study is other
      if (key === "studyLocation") {
        if (formState.study === "Anders") {
          errText = "";
        }
      }

      // parental signature is not required when the applicant is an adult
      if (key === "parentalSignature") {
        if (!getAgeText(formState.birthDate)) {
          // Hacky way to ensure the signature is cleared when the user switches from minor to adult
          formState.parentalSignature = null;
          errText = "";
        }
      }

      errorState = {
        ...errorState,
        [key]: errText
      };
    });

    // Handle other error cases
    errorState = {
      ...errorState,
      // Study start year was not before 2000 and not after currentYear + 1
      studyStartYear: !formState.studyStartYear
        ? errorState.studyStartYear
        : formState.studyStartYear > (new Date().getFullYear() - 15) && formState.studyStartYear < (new Date().getFullYear() + 2)
          ? ""
          : t("input.studyStartYear") + " " + t("text.isInvalid"),
      // User is not below 16 years of age
      birthDate: !formState.birthDate
        ? errorState.birthDate
        : parseAge(formState.birthDate) >= minSignupAge
          ? ""
          : t("input.birthDate") + " " + t("text.isInvalid") + ". " + t("text.contactDirectly"),
      // Email is valid and is not a student mail
      email: !formState.email
        ? errorState.email
        : /^(.+@.+\..+)$/.test(
          formState.email.toLowerCase()
        )
          ? /^(.*student.*)$/.test(formState.email.toLowerCase())
            ? t("text.dontUseStudentMail")
            : ""
          : t("input.email") + " " + t("text.isInvalid"),
      // User's name does not contain numbers
      nickName: !formState.nickName
        ? errorState.nickName
        : /^(^\D+)$/.test(formState.nickName)
          ? ""
          : t("input.nickName") + " " + t("text.isInvalid"),
      initials: !formState.initials
        ? errorState.initials
        : /^(^\D+)$/.test(formState.initials)
          ? ""
          : t("input.initials") + " " + t("text.isInvalid"),
      lastName: !formState.lastName
        ? errorState.lastName
        : /^(^\D+)$/.test(formState.lastName)
          ? ""
          : t("input.lastName") + " " + t("text.isInvalid"),
      houseNumber: !formState.houseNumber
        ? errorState.houseNumber
        : /^(\d+\w*)$/.test(formState.houseNumber)
          ? ""
          : t("input.houseNumber") + " " + t("text.isInvalid"),
      // Phone numbers consists solely of 0-9 - + ( and )
      phoneNumber: !formState.phoneNumber
        ? errorState.phoneNumber
        : /^([\d-+]*)$/.test(formState.phoneNumber)
          ? ""
          : t("input.phoneNumber") + " " + t("text.isInvalid"),
      // Student number consists of 8 digits and doesn't start with a 0 iff study is not "other"
      studentNumber: !formState.studentNumber
        ? errorState.studentNumber
        : formState.study !== "other"
          ? /^(\d{8})$/.test(formState.studentNumber)
            ? ""
            : t("input.studentNumber") + " " + t("text.isInvalid")
          : "",
      statementAgreement: formState.statementAgreement ? "" : t("text.termsNotAgreed") + " " + t("text.isRequired")
    };

    return errorState;
  };

  // Define options for study, studyLocation and referrer
  // FIXME : define these options in one location or retrieve them from the backend (src/types/userDTO.d.ts)
  const studyOptions = ["ADS-AI", "CMD", "HBO-ICT", "HBO-ICT-D", "HBO-ICT-BDM", "HBO-ICT-ISM", "HBO-ICT-ID", "HBO-ICT-NSE", "HBO-ICT-SE", "UXD", "Anders"];
  const studyLocationOptions = ["Den Haag", "Zoetermeer", "Delft", "Anders"];
  const referrerOptions = ["Haag Uit", "Open Dag", "Kennissen", "Online", "Anders"];

  // Hooks
  const {
    formState,
    errorState,
    setFormState,
    handleFormChange,
    validateForm,
    validateSingle,
    formIsValid,
    getEmptyErrorState
  } =
    useForm<User>(initialFormState, formValidator);
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation("form");
  const theme = useTheme();
  const history = useHistory();
  const [modalState, setModalState] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const closeModal = () => setModalState(false);
  const openModal = () => setModalState(true);

  // Event handlers
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // Prevent default submit action
    e.preventDefault();

    // Get the current error state
    const errState = validateForm(formState);

    // Submit form if the form is valid
    if (formIsValid(errState)) {
      openModal();
    } else {
      enqueueSnackbar(Object.values(errState).filter((val) => !!val)[0], { variant: "error" });
      console.log(errState);
    }
  };

  const performSubmit = async () => {
    // Get the current error state
    const errState = validateForm(formState);

    // Submit form if the form is valid
    if (formIsValid(errState)) {
      setIsSubmitting(true);
      const submissionResult = await submitForm(formState);

      if (submissionResult) {
        enqueueSnackbar(t("text.submissionSuccess"), { variant: "success" });
        history.push("/confirmation", { fromApp: true });
      } else {
        enqueueSnackbar(t("text.submissionError"), { variant: "error" });
      }

      setIsSubmitting(false);
    } else {
      enqueueSnackbar(Object.values(errState).filter((val) => !!val)[0], { variant: "error" });
      console.log(errState);
    }
  };

  const handleInitialsOnBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    formState.initials =
      formState.initials
        .replace(/[." "]/g, "")
        .toUpperCase()
        .split("")
        .join(".") + (formState.initials.length ? "." : "");
    return validateSingle(e);
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (!date) {
      return;
    }

    setFormState((oldFormState) => {
      return {
        ...oldFormState,
        birthDate: date.toDate()
      };
    });
  };

  const handleSelectChange = (e: SelectChangeEvent) => handleFormChange(e as unknown as React.ChangeEvent<HTMLInputElement>);

  return (
    <Container>
      <Modal open={modalState} onClose={closeModal}>
        <ConfirmationModalContent user={formState} onClose={closeModal} submit={performSubmit}
          isSubmitting={isSubmitting} />
      </Modal>

      <BackDiv>
        <a href="https://www.svsim.nl/">
          <IconButton>
            <ArrowBackIos color="primary" />
          </IconButton>
        </a>

        <Typography variant="body1" style={{ color: theme.palette.primary.light }}>
          Home &gt; {t("text.becomeMember")}
        </Typography>
      </BackDiv>
      <Box my={2} mx="auto" maxWidth="500px">
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Header>{t("header.personalInfo")}</Header>
          <StyledBox>
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="nickName"
              name="nickName"
              label={t("input.nickName")}
              variant="outlined"
              value={formState.nickName}
              helperText={errorState.nickName}
              error={!!errorState.nickName}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="initials"
              name="initials"
              label={t("input.initials")}
              variant="outlined"
              value={formState.initials.toUpperCase()}
              helperText={errorState.initials}
              error={!!errorState.initials}
              onBlur={handleInitialsOnBlur}
              onChange={handleFormChange}
            />
          </StyledBox>
          <StyledBox>
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="lastName"
              name="lastName"
              label={t("input.lastName")}
              variant="outlined"
              value={formState.lastName}
              helperText={errorState.lastName}
              error={!!errorState.lastName}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
          </StyledBox>
          <StyledBox>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
              <DatePicker
                label={t("input.birthDate")}
                onChange={handleDateChange}
                value={formState.birthDate}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    required
                    InputLabelProps={{ required: false }}
                    id="birthDate"
                    name="birthDate"
                    label={t("input.birthDate")}
                    variant="outlined"
                    value={formState.birthDate}
                    helperText={getAgeText(formState.birthDate) ? getAgeText(formState.birthDate) : errorState.birthDate}
                    error={!!errorState.birthDate}
                  />
                )}
              />
            </LocalizationProvider>
          </StyledBox>
          <br />
          <Header>{t("header.studyInfo")}</Header>
          <StyledBox>
            <StyledFormControl variant="outlined" required error={!!errorState.study}>
              <InputLabel id="studyLabel" required={false}>
                {t("input.study")}
              </InputLabel>
              <Select
                id="study"
                name="study"
                labelId="studyLabel"
                value={formState.study}
                error={!!errorState.study}
                onBlur={validateSingle}
                onChange={handleSelectChange}
                label={t("input.study")}
              >
                {studyOptions.map((study) => (
                  <MenuItem key={study} value={study}>
                    {t("study." + study)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errorState.study}</FormHelperText>
            </StyledFormControl>
          </StyledBox>
          {
            // Only show this when study is not other or unlisted
            formState.study !== "Anders" && studyOptions.includes(formState.study) ? (
              <StyledBox>
                <StyledFormControl variant="outlined" error={!!errorState.studyLocation}>
                  <InputLabel id="studyLocationLabel">{t("input.studyLocation")}</InputLabel>
                  <Select
                    id="studyLocation"
                    name="studyLocation"
                    labelId="studyLocationLabel"
                    value={formState.studyLocation}
                    onBlur={validateSingle}
                    onChange={handleSelectChange}
                    label={t("input.studyLocation")}
                  >
                    {studyLocationOptions.map((studyLocation) => (
                      <MenuItem key={studyLocation} value={studyLocation}>
                        {t("studyLocation." + studyLocation)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errorState.studyLocation}</FormHelperText>
                </StyledFormControl>
              </StyledBox>
            ) : (
              <></>
            )
          }
          <StyledBox>
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="studentNumber"
              name="studentNumber"
              label={t("input.studentNumber")}
              variant="outlined"
              value={formState.studentNumber}
              helperText={errorState.studentNumber}
              error={!!errorState.studentNumber}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="studyStartYear"
              name="studyStartYear"
              label={t("input.studyStartYear")}
              variant="outlined"
              type="number"
              value={formState.studyStartYear === 0 ? "" : formState.studyStartYear}
              helperText={errorState.studyStartYear}
              error={!!errorState.studyStartYear}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
          </StyledBox>
          <StyledBox>
            <StyledFormControl variant="outlined" required error={!!errorState.referrer}>
              <InputLabel id="referrerLabel" required={false}>
                {t("input.referrer")}
              </InputLabel>
              <Select
                id="referrer"
                name="referrer"
                labelId="referrerLabel"
                value={formState.referrer}
                onBlur={validateSingle}
                onChange={handleSelectChange}
                label={t("input.referrer")}
              >
                {referrerOptions.map((referrer) => (
                  <MenuItem key={referrer} value={referrer}>
                    {t("referrer." + referrer)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errorState.referrer}</FormHelperText>
            </StyledFormControl>
          </StyledBox>
          <br />
          <Header>{t("header.contactInfo")}</Header>
          <StyledBox>
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="street"
              name="street"
              label={t("input.street")}
              variant="outlined"
              value={formState.street}
              helperText={errorState.street}
              error={!!errorState.street}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="houseNumber"
              name="houseNumber"
              label={t("input.houseNumber")}
              variant="outlined"
              value={formState.houseNumber}
              helperText={errorState.houseNumber}
              error={!!errorState.houseNumber}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
          </StyledBox>
          <StyledBox>
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="postalCode"
              name="postalCode"
              label={t("input.postalCode")}
              variant="outlined"
              value={formState.postalCode}
              helperText={errorState.postalCode}
              error={!!errorState.postalCode}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="city"
              name="city"
              label={t("input.city")}
              variant="outlined"
              value={formState.city}
              helperText={errorState.city}
              error={!!errorState.city}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
          </StyledBox>
          <StyledBox>
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="phoneNumber"
              name="phoneNumber"
              label={t("input.phoneNumber")}
              variant="outlined"
              type="tel"
              value={formState.phoneNumber}
              helperText={errorState.phoneNumber}
              error={!!errorState.phoneNumber}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
          </StyledBox>
          <StyledBox>
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="email"
              name="email"
              label={t("input.email")}
              variant="outlined"
              value={formState.email}
              helperText={errorState.email ? errorState.email : t("text.dontUseStudentMail")}
              error={!!errorState.email}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
          </StyledBox>
          <br />
          <Header>{t("header.agreementInfo")}</Header>
          <Typography>
            <Trans i18nKey="form:text.agreementInfo">
              <Link rel="noopener noreferrer" href="https://svsim.nl/algemene-voorwaarden"
                target="_blank"></Link>
              <Link rel="noopener noreferrer" href="https://svsim.nl/statuten" target="_blank"></Link>
              <Link rel="noopener noreferrer" href="https://svsim.nl/hr" target="_blank"></Link>
              <Link rel="noopener noreferrer" href="https://svsim.nl/privacyverklaring"
                target="_blank"></Link>
            </Trans>
          </Typography>
          <FormControl required error={!!errorState.statementAgreement}>
            <FormControlLabel
              control={
                <Checkbox
                  id="statementAgreement"
                  name="statementAgreement"
                  checked={formState.statementAgreement}
                  onChange={handleFormChange}
                  color="primary"
                />
              }
              label={t("input.statementAgreement")}
            />
            <FormHelperText>{errorState.statementAgreement}</FormHelperText>
          </FormControl>

          <br />
          <br />

          <Header>{t("header.paymentInfo")}</Header>
          <Typography>{t("text.paymentInfo")}</Typography>
          <br />
          <StyledBox>
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="accountHolder"
              name="accountHolder"
              label={t("input.accountHolder")}
              variant="outlined"
              value={formState.accountHolder}
              helperText={errorState.accountHolder}
              error={!!errorState.accountHolder}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
          </StyledBox>
          <StyledBox>
            <StyledTextField
              required
              InputLabelProps={{ required: false }}
              id="bankAccount"
              name="bankAccount"
              label={t("input.bankAccount")}
              variant="outlined"
              value={formState.bankAccount}
              helperText={errorState.bankAccount}
              error={!!errorState.bankAccount}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
          </StyledBox>

          <StyledBox>
            <StyledDrawInput
              rowCount={7}
              required
              InputLabelProps={{ required: false }}
              id="signature"
              name="signature"
              label={t("input.signature")}
              variant="outlined"
              value={formState.signature}
              helperText={errorState.signature}
              error={!!errorState.signature}
              onBlur={validateSingle}
              onChange={handleFormChange}
            />
          </StyledBox>



          {
            // Hijack getAgeText because it already does the < 18 comparison
            (formState.birthDate && getAgeText(formState.birthDate)) ? (
              <StyledBox>
                <StyledDrawInput
                  rowCount={7}
                  required={false}
                  InputLabelProps={{ required: false }}
                  id="parentalSignature"
                  name="parentalSignature"
                  label={t("input.parentalSignature")}
                  variant="outlined"
                  value={formState.parentalSignature}
                  helperText={errorState.parentalSignature}
                  error={!!errorState.parentalSignature}
                  onBlur={validateSingle}
                  onChange={handleFormChange}
                />
              </StyledBox>
            )
              :
              <></>
          }

          <br />
          <StyledButton type="submit" variant="contained" color="primary" id="submitbutton">
            {t("header.submit")}
          </StyledButton>
        </form>
      </Box>
    </Container>
  );
};

const BackDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTextField = styled(TextField)`
  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type="number"] {
    -moz-appearance: textfield;
  }

  margin: 6px;
  flex-grow: 1;
`;

const StyledDrawInput = styled(DrawInput)`
  margin: 6px;
  flex-grow: 1;
`;

const StyledFormControl = styled(FormControl)`
  margin: 6px;
  flex-grow: 1;
`;

const StyledBox = styled(Box)`
  display: flex;
  justify-content: stretch;
`;

const StyledButton = styled(Button)`
  margin: 6px;
`;

export default Home;
