import React from "react";
import { AppBar, Icon, IconButton, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import FlagNL from "../assets/nl.svg";
import FlagUK from "../assets/uk.svg";

export const Header: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation("form");

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          {t("header.signUpForm")}
        </Typography>
        <div>
          <IconButton onClick={() => i18n.changeLanguage("nl")} size="large">
            <Icon style={{ width: "1.5em", height: "1.125em", outline: "2px solid " + (i18n.language === "nl" ? "white" : "black") }}>
              <img id="nl" alt="flag_nl" src={FlagNL} />
            </Icon>
          </IconButton>
          <IconButton onClick={() => i18n.changeLanguage("en-gb")} size="large">
            <Icon style={{ width: "1.5em", height: "1.125em", outline: "2px solid " + (i18n.language === "en-gb" ? "white" : "black") }}>
              <img alt="flag_uk" src={FlagUK} />
            </Icon>
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
