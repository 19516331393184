import React from "react";
import { Box, Container } from "@mui/material";
import Header from "./header";

export const Layout: React.FunctionComponent = ({ children }) => {
  return (
    <>
      <Header />
      <Container>
        <Box my={1}>{children}</Box>
      </Container>
    </>
  );
};

export default Layout;
