import { useLocation } from "react-router-dom";
import { SVSIM_URL } from "../constants";

const FromApp: React.FunctionComponent = ({ children }) => {
  const { state } = useLocation<{ fromApp?: boolean }>();

  if (!state?.fromApp) {
    window.location.href = SVSIM_URL;
    return null;
  }

  return <>{children}</>;
};

export default FromApp;
