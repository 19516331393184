import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { User } from "../../types";
import styled from "styled-components";
import { Cake, Close, Contacts, Create, Email, GroupAdd, Home, Payment, Person, School } from "@mui/icons-material";

export const ConfirmationModalContent = ({
  user,
  onClose,
  submit,
  isSubmitting,
}: {
  user: User;
  onClose: () => void;
  submit: () => void;
  isSubmitting: boolean;
}): React.ReactElement => {
  const { t, i18n } = useTranslation("form");

  return (
    <ModalWrapper>
      <ModalContentWrapper p={2} maxWidth="500px">
        <CenterVertically style={{ justifyContent: "space-between" }}>
          <Typography variant="h4" color="primary">
            {t("header.confirm")}
          </Typography>
          <IconButton onClick={onClose} size="large">
            <Close color="primary" />
          </IconButton>
        </CenterVertically>
        <Typography>{t("text.checkData")}</Typography>
        <hr />

        {/* Personal info */}
        <Typography variant="h6" color="primary">
          {t("header.personalInfo")}
        </Typography>
        <Typography>
          <CenterVertically mb={1}>
            <CenterVertically mr={1}>
              <Person />
            </CenterVertically>
            {user.nickName} ({user.initials}) {user.lastName}
          </CenterVertically>
          <CenterVertically mb={1}>
            <CenterVertically mr={1}>
              <Cake />
            </CenterVertically>
            {user.birthDate?.toLocaleDateString(i18n.language, { year: "numeric", month: "long", day: "numeric" })}
          </CenterVertically>
        </Typography>
        <br />

        {/* Study info */}
        <Typography variant="h6" color="primary">
          {t("header.studyInfo")}
        </Typography>
        <Typography>
          <CenterVertically mb={1}>
            <CenterVertically mr={1}>
              <School />
            </CenterVertically>
            {t(`study.${user.study}`)} {user.studyLocation ? "- " + t(`studyLocation.${user.studyLocation}`) : ""} {t("text.from")}{" "}
            {user.studyStartYear}
          </CenterVertically>
          <CenterVertically mb={1}>
            <CenterVertically mr={1}>
              <School />
            </CenterVertically>
            {user.studentNumber}
          </CenterVertically>
          <CenterVertically mb={1}>
            <CenterVertically mr={1}>
              <GroupAdd />
            </CenterVertically>
            {t("input.referrer")} {t(`referrer.${user.referrer}`)}
          </CenterVertically>
        </Typography>
        <br />

        {/* Contact info */}
        <Typography variant="h6" color="primary">
          {t("header.contactInfo")}
        </Typography>
        <Typography>
          <CenterVertically mb={1}>
            <CenterVertically mr={1}>
              <Home />
            </CenterVertically>
            {user.street} {user.houseNumber}, {user.postalCode} {user.city}
          </CenterVertically>
          <CenterVertically mb={1}>
            <CenterVertically mr={1}>
              <Contacts />
            </CenterVertically>
            {user.phoneNumber}
          </CenterVertically>
          <CenterVertically mb={1}>
            <CenterVertically mr={1}>
              <Email />
            </CenterVertically>
            {user.email}
          </CenterVertically>
        </Typography>
        <br />

        {/* Payment info */}
        <Typography variant="h6" color="primary">
          {t("header.paymentInfo")}
        </Typography>
        <Typography>
          <CenterVertically mb={1}>
            <CenterVertically mr={1}>
              <Person />
            </CenterVertically>
            {user.accountHolder}
          </CenterVertically>
          <CenterVertically mb={1}>
            <CenterVertically mr={1}>
              <Payment />
            </CenterVertically>
            {user.bankAccount}
          </CenterVertically>
          <CenterVertically mb={1}>
            <CenterVertically mr={1}>
              <Create />
            </CenterVertically>
            <img src={user.signature} alt={t("input.signature")} />
          </CenterVertically>

          {user.parentalSignature ? (
            <CenterVertically mb={1}>
              <CenterVertically mr={1}>
                <Create />
              </CenterVertically>
              <img src={user.parentalSignature} alt={t("input.parentalSignature")} />
            </CenterVertically>
          ) : (
            <></>
          )}
        </Typography>

        <hr />

        <CenterVertically style={{ justifyContent: "flex-end" }}>
          <Button onClick={onClose} color="secondary" id="confirmCancelButton">
            {t("text.cancel")}
          </Button>

          <Box style={{ position: "relative" }}>
            <Button onClick={submit} disabled={isSubmitting} color="primary" id="confirmSubmitButton">
              {t("header.submit")}
            </Button>
            {isSubmitting && (
              <CircularProgress
                size={24}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </CenterVertically>
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

const CenterVertically = styled(Box)`
  display: flex;
  align-items: center;
`;

const ModalContentWrapper = styled(Box)`
  margin: auto;
  background-color: white;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;

  @media (max-width: 576px) {
    width: 90%;
  }
`;

const ModalWrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
`;
