import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { SnackbarKey, SnackbarProvider } from "notistack";

import "./actions/axios-config";

import form_en from "./translations/en/form.json";
import form_nl from "./translations/nl/form.json";
import { IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "nl",
  resources: {
    en: { form: form_en },
    nl: { form: form_nl },
  },
});

// Add action to all snackbars
const notistackRef = React.createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey) => () => {
  (notistackRef.current as SnackbarProvider).closeSnackbar(key);
};

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <SnackbarProvider
        ref={notistackRef}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)} size="large">
            <Clear style={{ color: "white" }} />
          </IconButton>
        )}
      >
        <App />
      </SnackbarProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
