import { IconButton, Typography, Box, useTheme, Link } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import Header from "../../components/text/header";
import { ACTIVE_MEMBER_URL, BENEFITS_URL, EVENTS_URL, SVSIM_URL } from "../../constants";

export const Confirmation = (): React.ReactElement => {
  const { t } = useTranslation("form");

  const theme = useTheme();

  return <>
    <BackDiv>
      <a href="https://www.svsim.nl/">
        <IconButton size="large">
          <ArrowBackIos color="primary" />
        </IconButton>
      </a>

      <Typography variant="body1" style={{ color: theme.palette.primary.light }}>
        Home &gt; {t("confirmation.title")}
      </Typography>
    </BackDiv>
    <Box my={2} mx="auto" maxWidth="500px">
      <Header>{t("confirmation.header")}</Header>
      <br />
      <Typography>
        <Trans i18nKey="form:confirmation.content">
          <Link rel="noopener noreferrer" href={SVSIM_URL} target="_blank"></Link>
          <Link rel="noopener noreferrer" href={BENEFITS_URL} target="_blank"></Link>
          <Link rel="noopener noreferrer" href={EVENTS_URL} target="_blank"></Link>
          <Link rel="noopener noreferrer" href={ACTIVE_MEMBER_URL} target="_blank"></Link>
        </Trans>
      </Typography>
    </Box>
  </>;
};

const BackDiv = styled.div`
  display: flex;
  align-items: center;
`;

export default Confirmation;
